.e489ca8621554af197d-logo2-1-icon12 {
    position: absolute;
    top: 7px;
    left: 0;
    width: 160px;
    height: 38.4px;
    object-fit: cover;
    display: none;
  }
  .datacreds-div21 {
    position: absolute;
    top: 13px;
    left: 0;
    line-height: 23.8px;
    font-weight: 500;
    display: none;
  }
  .group-icon61,
  .group-icon62 {
    position: relative;
    flex-shrink: 0;
  }
  .group-icon61 {
    width: 31.02px;
    height: 30.98px;
    display: none;
  }
  .group-icon62 {
    width: 40.96px;
    height: 40.96px;
  }
  .datacreds-div22 {
    position: relative;
    line-height: 23.8px;
    font-weight: 500;
    display: inline-block;
  }
  .frame-div1628 {
    position: absolute;
    top: 8px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    color: #fff;
  }
  .frame-div1628 > * + * {
    margin-left: 6px;
  }
  .logo-hare-div4 {
    position: relative;
    width: 241px;
    height: 50px;
    flex-shrink: 0;
  }
  .manu-div4 {
    position: relative;
    line-height: 23.8px;
    display: inline-block;
    text-decoration: none;
    color: #333;
  }
  .frame-div1632 {
    position: relative;
    width: 10px;
    height: 95px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .icon103,
  .vuesaxoutlinebank-div19 {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .vuesaxoutlinebank-div19 {
    border-radius: 8000px;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(247, 247, 251, 0.01);
    box-sizing: border-box;
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .my-models-div11 {
    position: relative;
    line-height: 23.8px;
    display: inline-block;
    width: 194px;
    flex-shrink: 0;
  }
  .frame-div1634 {
    width: 250px;
    display: flex;
    flex-direction: row;
    padding: 5px 50px 6px 8px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div1634 > * + * {
    margin-left: 10px;
  }
  .rectangle-icon96,
  .rectangle-icon97 {
    position: absolute;
    top: -29px;
    left: 213px;
    width: 37px;
    height: 29px;
  }
  .rectangle-icon97 {
    top: 93px;
  }
  .dashboard-icon8,
  .new-model-div4 {
    position: relative;
    width: 46px;
    height: 46px;
    flex-shrink: 0;
  }
  .new-model-div4 {
    line-height: 23.8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 156px;
    height: 20px;
  }
  .frame-div1638 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 80px 0 0 80px;
    background-color: #db535b;
    width: 250px;
    display: flex;
    flex-direction: row;
    padding: 9px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div1638 > * + * {
    margin-left: 10px;
  }
  .frame-div1637 {
    position: relative;
    width: 250px;
    height: 64px;
    flex-shrink: 0;
    display: none;
    color: #fff;
    font-family: Poppins;
  }
  .frame-div1633 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1633 > * + * {
    margin-top: 10px;
  }
  .frame-div1631 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.6);
  }
  .frame-div1631 > * + * {
    margin-left: 1px;
  }
  .frame-div1630 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1630 > * + * {
    margin-top: 17px;
  }
  .rectangle-icon98,
  .rectangle-icon99 {
    position: absolute;
    top: -29px;
    left: 193px;
    width: 37px;
    height: 29px;
  }
  .rectangle-icon99 {
    top: 93px;
  }
  .overview-div4 {
    position: relative;
    line-height: 23.8px;
    font-weight: 500;
    display: inline-block;
    width: 156px;
    height: 20px;
    flex-shrink: 0;
  }
  .frame-div1644 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 80px 0 0 80px;
    background-color: #ff7364;
    display: flex;
    flex-direction: row;
    padding: 9px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .frame-div1644 > * + * {
    margin-left: 10px;
  }
  .frame-div1643 {
    position: relative;
    width: 230px;
    height: 74px;
    flex-shrink: 0;
    display: none;
  }
  .active-model-div3 {
    position: relative;
    line-height: 23.8px;
    display: inline-block;
    width: 125px;
    flex-shrink: 0;
  }
  .frame-div1646 {
    display: flex;
    flex-direction: row;
    padding: 5px 50px 6px 8px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    z-index: 0;
    font-size: 17px;
    color: #fff;
  }
  .frame-div1646 > * + * {
    margin-left: 10px;
  }
  .buld-div3,
  .frame-icon85 {
    position: relative;
    flex-shrink: 0;
  }
  .frame-icon85 {
    width: 15px;
    height: 12.49px;
  }
  .buld-div3 {
    line-height: 23.8px;
    display: inline-block;
    width: 105px;
  }
  .frame-div1647 {
    width: 230px;
    display: flex;
    flex-direction: row;
    padding: 5px 50px 6px 8px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
  }
  .frame-div1647 > * + * {
    margin-left: 10px;
  }
  .vector-icon99 {
    position: relative;
    width: 12.49px;
    height: 7.14px;
    flex-shrink: 0;
  }
  .frame-div1651 {
    width: 230px;
    height: 38px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: 5px 50px 6px 8px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
  }
  .frame-div1651 > * + * {
    margin-left: 10px;
  }
  .vector-icon100 {
    position: relative;
    width: 12.49px;
    height: 7.14px;
    flex-shrink: 0;
    display: none;
  }
  .frame-div1653 {
    width: 230px;
    display: flex;
    flex-direction: row;
    padding: 5px 50px 6px 8px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
  }
  .frame-div1653 > * + * {
    margin-left: 10px;
  }
  .frame-div1654 {
    width: 230px;
    display: flex;
    flex-direction: row;
    padding: 5px 50px 6px 8px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    color: #db535b;
  }
  .frame-div1654 > * + * {
    margin-left: 10px;
  }
  .frame-div1652 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }
  .frame-div1652 > * + * {
    margin-top: -2px;
  }
  .frame-div1649 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-icon86 {
    position: relative;
    width: 15px;
    height: 12.49px;
    flex-shrink: 0;
    z-index: 0;
  }
  .new-div3,
  .workflow-div3 {
    position: relative;
    display: inline-block;
  }
  .workflow-div3 {
    line-height: 23.8px;
    width: 105px;
    flex-shrink: 0;
    z-index: 1;
  }
  .new-div3 {
    line-height: 22.8px;
  }
  .frame-div1659,
  .frame-div1660 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .frame-div1660 {
    margin: 0 !important;
    position: absolute;
    top: 5px;
    left: 175px;
    border-radius: 3px;
    background-color: rgba(84, 111, 255, 0.07);
    border: 1px solid #db535b;
    padding: 0 10px;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
    font-size: 10px;
    color: #db535b;
  }
  .frame-div1659 {
    width: 230px;
    padding: 5px 50px 6px 8px;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
  .frame-div1659 > * + * {
    margin-left: 10px;
  }
  .frame-div1648 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
  }
  .frame-div1648 > * + * {
    margin-top: 28px;
  }
  .riarrow-drop-down-line-icon3 {
    position: absolute;
    margin: 0 !important;
    top: 22.5px;
    left: -14px;
    width: 14px;
    height: 8px;
    flex-shrink: 0;
    overflow: hidden;
    z-index: 3;
  }
  .rectangle-div65,
  .rectangle-div66 {
    position: absolute;
    left: 0.5px;
    border-radius: 8000px;
    width: 2px;
  }
  .rectangle-div65 {
    top: 0;
    background-color: rgba(255, 255, 255, 0.26);
    height: 157px;
  }
  .rectangle-div66 {
    top: 30px;
    background-color: #db535b;
    height: 34px;
  }
  .frame-div1662 {
    position: absolute;
    margin: 0 !important;
    top: 134px;
    left: 58.5px;
    width: 2px;
    height: 112px;
    flex-shrink: 0;
    z-index: 4;
  }
  .frame-div1645 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    font-family: SF Pro Display;
  }
  .frame-div1645 > * + * {
    margin-top: -2px;
  }
  .frame-div1642 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1642 > * + * {
    margin-top: 6px;
  }
  .frame-div1640 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .frame-div1640 > * + * {
    margin-left: 1px;
  }
  .frame-div1629,
  .frame-div1639 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1639 {
    color: #fff;
    font-family: Poppins;
  }
  .frame-div1629 {
    color: rgba(255, 255, 255, 0.66);
    font-family: SF Pro Display;
  }
  .frame-div1629 > * + * {
    margin-top: 10px;
  }
  .overview-manu-div12 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #171725;
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.08);
    height: 1080px;
    display: flex;
    flex-direction: column;
    padding: 30px 0 0 20px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 17px;
    font-family: Inter;
  }
  .overview-manu-div12 > * + * {
    margin-top: 40px;
  }
  .pages-remaining-for-this-month9 {
    position: relative;
    line-height: 26.4px;
    display: inline-block;
  }
  .vector-icon105 {
    position: relative;
    width: 18px;
    height: 12.66px;
    flex-shrink: 0;
  }
  .frame-div1664 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
    background-color: #db535b;
    border: 1px solid #db535b;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 11px 14px;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div1664 > * + * {
    margin-left: 10px;
  }
  .group-div52 {
    position: absolute;
    top: 0;
    left: 0;
    width: 203px;
    height: 46px;
  }
  .group-div51 {
    position: relative;
    width: 203px;
    height: 46px;
    flex-shrink: 0;
    font-size: 17px;
    color: #fff;
  }
  .frame-div1663 {
    position: absolute;
    top: 0;
    left: 282px;
    background-color: #fff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
    width: 1638px;
    display: flex;
    flex-direction: row;
    padding: 21px 40px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    color: #454c75;
  }
  .image-2-icon3 {
    position: relative;
    border-radius: 6px;
    width: 114px;
    height: 96px;
    flex-shrink: 0;
    object-fit: cover;
  }
  .set-up-your-invoices-workflow3 {
    position: relative;
    line-height: 26.4px;
    font-weight: 600;
    display: inline-block;
  }
  .automate-processes-with-our-ad3 {
    position: relative;
    font-size: 20px;
    line-height: 23.8px;
    display: inline-block;
  }
  .frame-div1668 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1668 > * + * {
    margin-top: 13px;
  }
  .frame-div1667 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frame-div1667 > * + * {
    margin-left: 20px;
  }
  .frame-icon88 {
    position: relative;
    width: 18px;
    height: 13.33px;
    flex-shrink: 0;
  }
  .frame-div1669 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 14px 16px;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div1669 > * + * {
    margin-left: 10px;
  }
  .group-div55 {
    position: absolute;
    top: 0;
    left: 0;
    width: 234px;
    height: 52px;
  }
  .group-div54 {
    position: relative;
    width: 234px;
    height: 52px;
    flex-shrink: 0;
    font-size: 20px;
    color: #db535b;
  }
  .frame-div1666 {
    border-radius: 16px;
    background-color: #db535b;
    box-shadow: 0 10px 30px rgba(219, 83, 91, 0.36), -5px -5px 4px #db535b inset;
    border: 1px solid #db535b;
    box-sizing: border-box;
    position: relative;
    width: 1559px;
    display: flex;
    flex-direction: row;
    padding: 34px 30px;
    align-items: center;
    justify-content: space-between;
  }
  .frame-icon89 {
    position: relative;
    width: 22.03px;
    height: 23.38px;
    flex-shrink: 0;
  }
  .frame-div1672 {
    border-radius: 8px;
    background-color: #f2f4f4;
    border: 1px solid #e5e6ed;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 14px 16px;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
  }
  .frame-div1672 > * + * {
    margin-left: 10px;
  }
  .frame-div1671 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div1671 > * + * {
    margin-left: 20px;
  }
  .frame-icon90 {
    position: relative;
    width: 19px;
    height: 19px;
    flex-shrink: 0;
  }
  .frame-div1670 {
    width: 1559px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    color: #454c75;
  }
  .frame-div1665 {
    position: absolute;
    top: 118px;
    left: 321px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #fff;
  }
  .frame-div1665 > * + * {
    margin-top: 30px;
  }
  .frame-div1681 {
    border-radius: 6px 0 0 6px;
    background-color: #db535b;
    border: 1px solid #db535b;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    align-items: center;
    justify-content: center;
  }
  .frame-icon91 {
    position: relative;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .frame-div1683 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frame-div1683 > * + * {
    margin-left: 4px;
  }
  .frame-div1682 {
    background-color: #fff;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    align-items: center;
    justify-content: center;
    color: #9aa0ae;
  }
  .entries-div3 {
    position: relative;
    line-height: 23.8px;
    display: inline-block;
    transform: rotate(180deg);
    transform-origin: 0 0;
  }
  .frame-div1680,
  .frame-div1684 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frame-div1684 {
    border-radius: 6px 0 0 6px;
    background-color: #fff;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    padding: 12px 16px;
    transform: rotate(180deg);
    transform-origin: 0 0;
    color: #92929d;
  }
  .frame-div1680 {
    position: absolute;
    top: 1.5px;
    left: 1px;
  }
  .frame-div1680 > * + * {
    margin-left: 1px;
  }
  .tab-show-entries9 {
    position: relative;
    border-radius: 10px;
    width: 328px;
    height: 51px;
    flex-shrink: 0;
  }
  .shape-div6 {
    position: absolute;
    height: 97.96%;
    width: 100%;
    top: 1.02%;
    right: 0;
    bottom: 1.02%;
    left: 0;
    border-radius: 8px;
    background-color: #fafafb;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
  }
  .componenticonic-chevron-down3 {
    position: absolute;
    top: calc(50% - 11px);
    right: 10px;
    width: 24px;
    height: 24px;
  }
  .search-from-message6 {
    position: absolute;
    top: calc(50% - 8px);
    left: 34px;
    letter-spacing: 0.1px;
    line-height: 24px;
    display: inline-block;
  }
  .componenticonic-search6 {
    position: absolute;
    height: 100%;
    width: 13.48%;
    top: 0;
    right: 86.52%;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
  }
  .search-field-div6,
  .text-icon6 {
    position: absolute;
    overflow: hidden;
  }
  .text-icon6 {
    top: calc(50% - 15px);
    left: 10px;
    width: 178px;
    height: 24px;
  }
  .search-field-div6 {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .btn-search-field-div6 {
    position: relative;
    width: 350px;
    height: 49px;
    flex-shrink: 0;
  }
  .shape-div7 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    background-color: #db535b;
    border: 1px solid #db535b;
    box-sizing: border-box;
  }
  .search-from-message7 {
    position: relative;
    letter-spacing: 0.1px;
    line-height: 24px;
    display: inline-block;
  }
  .frame-div1686 {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1686 > * + * {
    margin-left: 5px;
  }
  .text-icon7 {
    position: absolute;
    top: calc(50% - 12px);
    left: 22px;
    width: 90px;
    height: 24px;
    overflow: hidden;
  }
  .btn-search-field-div7 {
    position: relative;
    width: 133px;
    height: 48px;
    flex-shrink: 0;
    color: #fff;
  }
  .frame-div1685 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #92929d;
    font-family: Roboto;
  }
  .frame-div1685 > * + * {
    margin-left: 20px;
  }
  .frame-div1679 {
    width: 1499px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .frame-icon92 {
    position: relative;
    width: 94px;
    height: 15px;
    flex-shrink: 0;
  }
  .frame-div1688 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .rectangle-icon100 {
    position: relative;
    border-radius: 0.5px;
    width: 7.99px;
    height: 5.37px;
    flex-shrink: 0;
  }
  .frame-div1691 {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1691 > * + * {
    margin-top: 1px;
  }
  .frame-div1690 {
    position: relative;
    width: 10px;
    height: 13.44px;
    flex-shrink: 0;
  }
  .file-name-div3 {
    position: relative;
    letter-spacing: 0.1px;
    display: inline-block;
    width: 385px;
    flex-shrink: 0;
  }
  .frame-div1689 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frame-div1689 > * + * {
    margin-left: 16px;
  }
  .validation-div3 {
    position: relative;
    letter-spacing: 0.1px;
    display: inline-block;
    width: 152px;
    flex-shrink: 0;
  }
  .manual-review-div3 {
    width: 186px;
    flex-shrink: 0;
  }
  .manual-review-div3,
  .uploaded-at-div3 {
    position: relative;
    letter-spacing: 0.1px;
    display: inline-block;
  }
  .frame-icon93 {
    position: relative;
    width: 10.67px;
    height: 6.67px;
    flex-shrink: 0;
  }
  .frame-div1693 {
    width: 180px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div1693 > * + * {
    margin-left: 6px;
  }
  .frame-icon94 {
    position: relative;
    width: 10.67px;
    height: 13.07px;
    flex-shrink: 0;
  }
  .frame-div1694 {
    width: 202px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div1694 > * + * {
    margin-left: 6px;
  }
  .frame-div1687,
  .frame-div1695 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .frame-div1695 {
    width: 106px;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .frame-div1687 {
    border-radius: 12px 12px 0 0;
    background-color: #f0f0f5;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    padding: 16px 20px;
    justify-content: center;
    text-align: left;
    font-size: 16px;
    color: #454c75;
    font-family: Roboto;
  }
  .frame-div1687 > * + * {
    margin-left: 30px;
  }
  .frame-div1678 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    font-size: 17px;
    color: #fff;
    font-family: SF Pro Display;
  }
  .frame-div1678 > * + * {
    margin-top: 33px;
  }
  .dec-div36 {
    position: relative;
    letter-spacing: 0.1px;
    font-weight: 500;
    display: inline-block;
    width: 23px;
    flex-shrink: 0;
  }
  .frame-div1698 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .rectangle-icon102 {
    position: absolute;
    top: -0.5px;
    left: -0.5px;
    border-radius: 3px;
    width: 50px;
    height: 52px;
    object-fit: cover;
  }
  .frame-div1699 {
    position: relative;
    width: 59px;
    height: 51px;
    flex-shrink: 0;
  }
  .frame-div1697 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
  }
  .frame-div1697 > * + * {
    margin-left: -2px;
  }
  .dec-div37 {
    position: relative;
    letter-spacing: 0.1px;
    display: inline-block;
    width: 395px;
    flex-shrink: 0;
  }
  .akar-iconscheck24,
  .akar-iconscheck25 {
    position: relative;
    width: 111px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .akar-iconscheck25 {
    width: 154px;
  }
  .dec-div38,
  .dec-div39 {
    position: relative;
    letter-spacing: 0.1px;
    display: inline-block;
    width: 145px;
    flex-shrink: 0;
  }
  .dec-div39 {
    text-decoration: underline;
    width: 203px;
  }
  .carbonoverflow-menu-horizonta-icon9 {
    position: relative;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .frame-div1696,
  .frame-div1700 {
    display: flex;
    flex-direction: row;
  }
  .frame-div1700 {
    width: 67px;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1696 {
    background-color: #fff;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    padding: 12px 20px;
    align-items: center;
    justify-content: center;
  }
  .frame-div1696 > * + * {
    margin-left: 50px;
  }
  .frame-div1701 {
    background-color: #fafafb;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
    align-items: center;
    justify-content: center;
  }
  .frame-div1701 > * + * {
    margin-left: 50px;
  }
  .frame-div1706 {
    border-radius: 0 0 12px 12px;
    background-color: #fff;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
    align-items: center;
    justify-content: center;
  }
  .frame-div1706 > * + * {
    margin-left: 50px;
  }
  .frame-div1677 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1677 > * + * {
    margin-top: 1px;
  }
  .frame-div1711 {
    position: relative;
    width: 24px;
    height: 10px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
  }
  .frame-div1713 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div1713 > * + * {
    margin-left: 6px;
  }
  .frame-div1715 {
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    align-items: center;
    justify-content: center;
  }
  .div159,
  .frame-div1716 {
    transform: rotate(180deg);
    transform-origin: 0 0;
  }
  .div159 {
    position: relative;
    letter-spacing: 0.1px;
    display: inline-block;
  }
  .frame-div1716 {
    border-radius: 6px;
    background-color: #db535b;
    padding: 10px 16px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .frame-div1714,
  .frame-div1716,
  .frame-div1717 {
    display: flex;
    flex-direction: row;
  }
  .frame-div1717 {
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    padding: 10px 16px;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    transform-origin: 0 0;
  }
  .frame-div1714 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1714 > * + * {
    margin-left: 7px;
  }
  .frame-div1712 {
    width: 388px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .pagination-div3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }
  .pagination-div3 > * + * {
    margin-top: 8px;
  }
  .frame-div1676 {
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .frame-div1676 > * + * {
    margin-top: 33px;
  }
  .carbonadd-icon9 {
    position: relative;
    width: 16px;
    height: 27px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .frame-div1719 {
    border-radius: 8px;
    background-color: #db535b;
    box-shadow: 0 6px 30px rgba(84, 111, 255, 0.16);
    display: flex;
    flex-direction: row;
    padding: 14px 16px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div1719 > * + * {
    margin-left: 10px;
  }
  .carbonadd-icon10,
  .carbonadd-icon11 {
    position: relative;
    width: 21px;
    height: 27px;
    flex-shrink: 0;
  }
  .carbonadd-icon11 {
    width: 17px;
  }
  .frame-div1721 {
    border-radius: 8px;
    border: 1.5px solid #db535b;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 14px 16px;
    align-items: flex-start;
    justify-content: flex-start;
    color: #db535b;
  }
  .frame-div1721 > * + * {
    margin-left: 10px;
  }
  .frame-div1718 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
    color: #fff;
    font-family: SF Pro Display;
  }
  .frame-div1718 > * + * {
    margin-left: 16px;
  }
  .frame-div1674 {
    position: absolute;
    top: 394px;
    left: 321px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #92929d;
    font-family: Roboto;
  }
  .frame-div1674 > * + * {
    margin-top: 40px;
  }
  .extract-data-div11 {
    position: relative;
    background-color: #fafafb;
    width: 100%;
    height: 1080px;
    overflow: hidden;
    text-align: left;
    font-size: 22px;
    color: #db535b;
    font-family: SF Pro Display;
  }
  

  th{
      padding: 0 30px;
  }

  .modal-button{
    background-color: #db535b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 16px;
    font-family: SF Pro Display;
    cursor: pointer;
    transform: translate(0, -50%);
    transition: all 0.3s ease;
  }
  .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }

  .modal-container{
    width: 50%;
    height: 80vh;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }


.drag-menu-item{
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* align-items: center; */
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  background-color: rgb(231, 231, 231);

    }

    .drag-menu-upload{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 30vh;
      width: 90%;
      margin: 0 auto;
      border-radius: 20px;
      background-color: #f5f5f5;
      /* box-shadow: 0px 10px 30px gray, inset -5px -5px 4px #dad2d3; */
    }
    .drag-menu-item-title{
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 41px;
      color: #454C75;
      margin: 25px;
      text-align: center;

    }
    .drag-menu-item-title h3{
      font-weight: bold;
    }
