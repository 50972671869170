*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.manage-fields{
    display: flex;
    border: 5px solid red;
}
.manage-fields__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

.manage-fields__body{
    /* position: absolute;
    top: 111px;
    left: 300px; */
    /* border-radius: 16px;
    background-color: rgba(243, 243, 243, 0.445);
    border: 1px solid #e5e6ed;
    
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 20px;
    color: #db535b; */
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 30px;
gap: 30px;

position: absolute;
width: 80%;
height: 70vh;
left: 250px;
top: 111px;
margin : 0 auto;


/* BG/Ash */

background: #F2F4F4;
/* BG/ Border */

border: 1px solid #E5E6ED;
border-radius: 16px;
}

.manage-fields__body__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -30px;
    gap: 30px;

}

.manage-fields__table-items{
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    color: #db535b;
    font-family: SF Pro Display;
    /* border: 4px solid saddlebrown; */
   
}

.manage-fields__body__left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 20px;
    color: #db535b;
    font-family: SF Pro Display;
    /* border: 3px solid blue; */
    width: 50%;
    gap: 7px;
}

.manage-fields__body__right{
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 20px;
    color: #db535b;
    font-family: SF Pro Display;
    /* border: 3px solid blue; */
    width: 50%;
    gap: 7px;
}


.inner-right-manage-fields{
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    /* color: #db535b; */
    font-family: SF Pro Display;
    /* border: 3px solid red; */
    /* width: 50%; */
    padding: 20px ;
   
    color: #171725;
    border-radius: 6px;
    width: 100%;
}

.manage-fields__body__right_right-icons{
    width: 10%;
    /* border: 4px solid red; */
}

.manage-fields-right__body-text{
    width: 90%;
    /* border: 4px solid yellow; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

}



.inner-left-manage-fields{
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    /* color: #db535b; */
    font-family: SF Pro Display;
    /* border: 3px solid red; */
    /* width: 50%; */
    padding: 20px ;
   
    color: #171725;
    border-radius: 6px;
    width: 100%;

}

.manage-fields__body__left_left-icons{
    width: 10%;
    /* border: 4px solid red; */
}

.manage-fields-left__body-text{
    width: 90%;
    /* border: 4px solid yellow; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

}

.done-btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    
}
.done-btn button{
    width: 50px;
    text-align: center;
    padding: 10px 70px;
}



  .training-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }

.vector-icon26 {
    position: relative;
    width: 13px;
    height: 10.4px;
    flex-shrink: 0;
  }
  .back-div {
    position: relative;
    line-height: 21px;
    display: inline-block;
  }
  .frame-div405 {
    border-radius: 6px;
    background-color: #f2f4f4;
    border: 1px solid #e5e6ed;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px 22px;
    align-items: center;
    justify-content: center;
  }
  .frame-div405 > * + * {
    margin-left: 7px;
  }
  .manage-fields-and-table-header {
    position: relative;
    font-size: 22px;
    line-height: 26.4px;
    color: #9aa0ae;
    text-align: left;
    display: inline-block;
  }
  .frame-icon39 {
    position: relative;
    width: 18px;
    height: 12.66px;
    flex-shrink: 0;
  }
  .frame-div406,
  .group-div13 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .frame-div406 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
  }
  .group-div13 {
    width: 38px;
    height: 32.66px;
  }
  .group-div12 {
    position: relative;
    width: 38px;
    height: 32.66px;
    flex-shrink: 0;
  }
  .frame-div404 {
    position: absolute;
    top: 0;
    left: 80px;
    background-color: #fff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
    width: 1840px;
    display: flex;
    flex-direction: row;
    padding: 20px 40px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
  }
  .btn-write-message-icon1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 48px;
    display: none;
  }
  .e489ca8621554af197d-logo2-1-icon2 {
    position: absolute;
    top: 5px;
    left: 2px;
    width: 40px;
    height: 38.4px;
    object-fit: cover;
    display: none;
  }
  .datacreds-div8 {
    position: absolute;
    top: 2px;
    left: 0;
    font-weight: 500;
    display: none;
    align-items: center;
    justify-content: center;
    width: 49px;
  }
  .group-icon26 {
    position: absolute;
    height: 85.33%;
    width: 93.09%;
    top: 0;
    right: 0.09%;
    bottom: 14.67%;
    left: 6.82%;
    max-width: 100%;
    max-height: 100%;
  }
  .frame-div410,
  .icon16 {
    position: relative;
    width: 44px;
    height: 48px;
    flex-shrink: 0;
  }
  .icon16 {
    width: 24px;
    height: 24px;
  }
  .frame-div412 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div412 > * + * {
    margin-top: 23px;
  }
  .icon20 {
    position: relative;
    width: 24px;
    height: 24.37px;
    flex-shrink: 0;
  }
  .frame-div411 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div411 > * + * {
    margin-top: 105px;
  }
  .frame-div409 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div409 > * + * {
    margin-top: 23px;
  }
  .frame-div407,
  .frame-div408 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div407 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #171725;
    height: 1108px;
    padding: 26px 18px;
    box-sizing: border-box;
  }
  .rectangle-div15 {
    position: absolute;
    top: 92px;
    left: 0;
    border-radius: 0 2px 2px 0;
    background-color: #fff;
    width: 3px;
    height: 36px;
  }
  .overview-manu-div2 {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.08);
    width: 80px;
    height: 1108px;
    font-size: 17px;
    color: #db535b;
    font-family: Inter;
  }
  .fields45-div {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
  }
  .line-div4 {
    position: relative;
    border-top: 2.5px solid #db535b;
    box-sizing: border-box;
    width: 94.5px;
    height: 2.5px;
    flex-shrink: 0;
  }
  .frame-div416 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div416 > * + * {
    margin-top: 7px;
  }
  .table-headers-3 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    color: #454c75;
    display: inline-block;
  }
  .frame-div415 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div415 > * + * {
    margin-left: 22px;
  }
  .carbonadd-alt-icon2,
  .tax-div {
    position: relative;
    flex-shrink: 0;
  }
  .carbonadd-alt-icon2 {
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .tax-div {
    line-height: 23.8px;
    display: inline-block;
    width: 644px;
  }
  .icoutline-arrow-drop-down-icon21 {
    position: relative;
    width: 22px;
    height: 12px;
    flex-shrink: 0;
  }
  .frame-div421 {
    border-radius: 10px;
    border: 1px solid #e5e6ed;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 20px 14px;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div421 > * + * {
    margin-left: 94px;
  }
  .frame-div420 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div420 > * + * {
    margin-left: 20px;
  }
  .frame-div429 {
    border-radius: 10px;
    border: 1px solid #3546b1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 20px 14px;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div429 > * + * {
    margin-left: 94px;
  }
  .frame-div419 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div419 > * + * {
    margin-top: 10px;
  }
  .frame-div418,
  .frame-div434,
  .frame-div435 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frame-div418,
  .frame-div434 {
    flex-direction: column;
    align-items: flex-start;
  }
  .frame-div418 {
    flex-direction: row;
  }
  .frame-div418 > * + * {
    margin-left: 30px;
  }
  .done-div {
    position: relative;
    line-height: 23.8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    flex-shrink: 0;
  }
  .frame-div460,
  .frame-div461 {
    display: flex;
    flex-direction: row;
  }
  .frame-div461 {
    border-radius: 8px;
    background-color: #db535b;
    box-shadow: 0 2px 12px rgba(84, 111, 255, 0.06);
    padding: 14px 13px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .frame-div460 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div417,
  .frame-div457 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .frame-div457 {
    text-align: center;
    font-size: 17px;
    color: #fff;
  }
  .frame-div417 {
    color: #9aa0ae;
  }
  .frame-div417 > * + * {
    margin-top: 30px;
  }
  .frame-div414 {
    position: absolute;
    top: 111px;
    left: 120px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #e5e6ed;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 20px;
    color: #db535b;
  }
  .frame-div414 > * + * {
    margin-top: 30px;
  }
  .rectangle-div16 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 1920px;
    height: 1080px;
  }
  .train-the-model-with-new-field {
    position: relative;
    line-height: 26.4px;
    font-weight: 600;
    display: inline-block;
  }
  .you-have-added-some-new-field {
    position: relative;
    font-size: 17px;
    line-height: 23.8px;
    color: #92929d;
    display: inline-block;
    width: 576px;
  }
  .frame-div463 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div463 > * + * {
    margin-top: 16px;
  }
  .frame-div462 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 0;
  }
  .frame-div466 {
    border-radius: 8px;
    background-color: #e5e6ed;
    border: 1px solid #e5e6ed;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 14px;
    align-items: center;
    justify-content: center;
  }
  .vector-icon33 {
    position: relative;
    width: 12.36px;
    height: 10.9px;
    flex-shrink: 0;
  }
  .frame-div467 {
    border-radius: 8px;
    background-color: #db535b;
    display: flex;
    flex-direction: row;
    padding: 12px 14px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .frame-div467 > * + * {
    margin-left: 10px;
  }
  .frame-div465 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frame-div465 > * + * {
    margin-left: 14px;
  }
  .frame-div464 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1;
    font-size: 15px;
    color: #9aa0ae;
  }
  .material-symbolscancel-rounde-icon1 {
    position: absolute;
    margin: 0 !important;
    top: 10px;
    right: 12px;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    overflow: hidden;
    z-index: 2;
  }
  .pup-up-div1 {
    position: absolute;
    top: 426px;
    left: 641px;
    border-radius: 14px;
    background-color: #f4f4f4;
    border: 1px solid #e8e8ea;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: left;
    font-size: 22px;
  }
  .pup-up-div1 > * + * {
    margin-top: 30px;
  }
  .manage-fields-div {
    position: relative;
    background-color: #fafafb;
    width: 100%;
    height: 1080px;
    overflow: hidden;
    text-align: center;
    font-size: 15px;
    color: #454c75;
    font-family: SF Pro Display;
  }
  